import PropTypes from "prop-types";
import React from "react";

import { baseEnvironmentConfig } from "@/api/constants";

import { ctaLarge } from "./index.module.scss";

const handleCTAClick = (url, newTab) => {
  if (!url) {
    return;
  }

  if (newTab) {
    return window.open(url, "_blank");
  }

  return (window.location.href = url);
};

const PrimaryCta = ({
  label,
  tabIndex,
  style,
  url,
  newTab,
  onClick,
  disabled = false,
  analyticsData,
}) => {
  const ctaStyle = style ? `${ctaLarge} ${style}` : `${ctaLarge}`;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (url) {
      handleCTAClick(url, newTab);
    }
  };

  return (
    <button
      aria-label={label}
      className={ctaStyle}
      data-adobe={analyticsData}
      data-testid={baseEnvironmentConfig.testIds.primaryCta}
      disabled={disabled}
      name="Buttons:PrimaryCTA"
      onClick={handleClick}
      tabIndex={tabIndex}
      title={label}
    >
      {label}
    </button>
  );
};

PrimaryCta.propTypes = {
  analyticsData: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.any,
  tabIndex: PropTypes.number,
  url: PropTypes.string,
};

export default PrimaryCta;
