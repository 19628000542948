import JoinCorpApiClient from "@/api/client/entities/join-corp";
import JoinLeadApiClient, {
  DEFAULT_JOIN_OUTREACHCODE,
  JOIN_OUTREACHCODE_SUFFIX,
} from "@/api/client/entities/join-lead";
import LeadAppointmentsApiClient from "@/api/client/entities/lead-appointments";
import LeadsApiClient from "@/api/client/entities/leads";
import { ACDL_GLOBAL_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/global-tracking";
import { ACDL_JOIN_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/join-flow";
import {
  ACDL_LEAD_FLOW_TRACKING,
  LEAD_FLOW_CATEGORY_TYPES,
  LEAD_FLOW_STEP_NAMES,
  LEAD_FLOW_TYPES,
} from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { utils as userFlowUtils } from "@/features/user-flow";
import FormattingUtils from "@/utils/helpers/formatting";

import { deriveEmailPayloadFromState, derivePayloadFromState } from "./utils";

const LeadFlowApi = {
  createNewLead: async (
    leadFlowState,
    setNewDataLayer,
    router,
    isEmbedded,
    isJoinCta = false
  ) => {
    try {
      const payload = derivePayloadFromState(leadFlowState);

      let result;

      if (isJoinCta) {
        result = await JoinLeadApiClient.createAbandonedLead({
          ...payload,
          id: null,
        });

        setNewDataLayer(
          ACDL_JOIN_FLOW_TRACKING.joinFlowLeadSubmit(
            FormattingUtils.encode(leadFlowState.contactInput["Email Address"]),
            result?.TransactionId,
            leadFlowState.isCorpLead
              ? leadFlowState.outreachCode
              : userFlowUtils.modifyOutreachCodeForVwoCampaign(
                  leadFlowState.joinOutreachCode ||
                    leadFlowState.outreachCode + JOIN_OUTREACHCODE_SUFFIX ||
                    DEFAULT_JOIN_OUTREACHCODE
                )
          )
        );
      } else {
        result = await LeadsApiClient.createAbandonedLead(payload);

        const facility =
          leadFlowState.scheduledFacility ||
          leadFlowState.selectedFacility ||
          leadFlowState.suggestedFacility;

        const { clubId, status, clubType, name, region } = facility;

        // Submit Lead ACDL Event
        if (result?.leadTransactionId) {
          const ACDLLeadSubmit = ACDL_LEAD_FLOW_TRACKING.leadFlowSubmitLead(
            LEAD_FLOW_STEP_NAMES.SUBMIT,
            isEmbedded
              ? LEAD_FLOW_TYPES.EMBEDDED
              : LEAD_FLOW_TYPES.NON_EMBEDDED,
            LEAD_FLOW_CATEGORY_TYPES.ENGAGE,
            facility.name,
            facility.facilityId,
            FormattingUtils.encode(leadFlowState.contactInput["Email Address"]),
            result.leadTransactionId,
            facility.city,
            status,
            clubType,
            clubId,
            userFlowUtils.modifyOutreachCodeForVwoCampaign(
              leadFlowState.outreachCode
            )
          );

          setNewDataLayer(ACDLLeadSubmit);
        }

        if (facility?.isAcceptingAppointments) {
          // Survey Load ACDL Event
          const ACDLInfo = ACDL_LEAD_FLOW_TRACKING.scheduleAVisit(
            router.asPath,
            {
              clubId,
              clubType,
              facilityId: facility.facilityId,
              name,
              region: region.toLowerCase().split("/")[0],
              status,
            }
          );

          setNewDataLayer(ACDLInfo);
        }

        setNewDataLayer(
          ACDL_GLOBAL_TRACKING.singleFlowLearnMore(router.asPath)
        );
      }

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  fetchWorkoutAppointments: async (facilityId) => {
    const workoutApiResponse = await fetch(
      `${process.env.NEXT_PUBLIC_EQUINOX_API_HOST}/v6/acq/leads/appointments?facilityId=${facilityId}&type=guided`
    );

    if (!workoutApiResponse.ok) {
      console.error("Error fetching", workoutApiResponse);

      return [];
    }

    const workoutApiData = await workoutApiResponse.json();

    return workoutApiData?.dateRangeWithTime;
  },

  /**
   *
   */
  getOpenLeadTimesFromFacilityId: async (facilityId) => {
    try {
      const result = await LeadAppointmentsApiClient.findById(facilityId);

      return result?.dateRangeWithTime || [];
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  updateAbandonedLead: async (leadFlowState) => {
    try {
      const payload = derivePayloadFromState(leadFlowState);
      const result = await LeadsApiClient.updateAbandonedLead(payload);

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  updateLead: async (leadFlowState) => {
    try {
      const payload = derivePayloadFromState(leadFlowState);
      const result = await LeadsApiClient.updateLead(payload);

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  createEmailCode: async (formState) => {
    try {
      const payload = deriveEmailPayloadFromState(formState);

      let result;

      if (formState) {
        result = await JoinCorpApiClient.createAbandonedCorpEmailVerify({
          ...payload,
          id: null,
        });
      }

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },
};

export default LeadFlowApi;
