import React from "react";

import styles from "./CorpEmailNonEligibleBanner.module.scss";

const CorpEmailNonEligibleBanner = () => {
  return (
    <div className={styles.bannerContainer}>
      Corporate memberships are not available with your company
    </div>
  );
};

export default CorpEmailNonEligibleBanner;
