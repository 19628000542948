import { v4 as uuidv4 } from "uuid";

import {
  NEWSLETTER_DECLINE_ANSWER_ID,
  NEWSLETTER_SUBSCRIBE_ANSWER_ID,
} from "@/constants";

import logger from "../../../utils/logger";
import ApiClient from "../client";

const JoinCorp = {
  namespace: "joinCorp",

  corpAbandonedPath: "/v6/acq/leads/corp/abandoned",
  emailResendBasePath: "/v6/acq/corp/email/verify/resend",
  emailTokenVerifyBasePath: "/v6/acq/corp/token/verify",
  emailVerifyBasePath: "/v6/acq/corp/email/verify",
  verifyDomainPath: "v6/acq/corp/domain/verify",

  /**
   *
   */
  async createAbandonedCorp({
    companyEmail,
    CTALabel,
    EmailAddress,
    FacilityId,
    FirstName,
    IsAbandonedLead,
    LastName,
    OutreachCode,
    PhoneNumber,
    receiveTextAlerts,
    SourceUrl,
    newsletter,
    UserLocation,
  }) {
    const TransactionId = uuidv4();

    return ApiClient._post(this.corpAbandonedPath, {
      data: {
        Answers: [
          {
            additionalInfo: "",
            answerId: newsletter
              ? NEWSLETTER_SUBSCRIBE_ANSWER_ID
              : NEWSLETTER_DECLINE_ANSWER_ID,
          },
        ],
        CTALabel,
        EmailAddress,
        FacilityId,
        FirstName,
        IsAbandonedLead,
        LastName,
        OutreachCode,
        PhoneNumber,
        SourceUrl,
        TransactionId,
        UserLocation,
        companyEmail,
        receiveTextAlerts,
      },
    }).then((resp) => {
      if (!resp.data) {
        logger.warn(`API|Join Corp: Could not generate corp`);

        return null;
      }

      return { ...resp, TransactionId };
    });
  },

  /**
   *
   */
  async createAbandonedCorpEmailVerify({
    firstName,
    lastName,
    personalEmail,
    corporateEmail,
    phoneNumber,
    facilityId,
  }) {
    const endpoint = `${this.emailVerifyBasePath}`;
    const TransactionId = uuidv4();

    return ApiClient._post(endpoint, {
      data: {
        corporateEmail,
        facilityId,
        firstName,
        lastName,
        personalEmail,
        phoneNumber,
      },
    }).then((resp) => {
      if (!resp.data) {
        logger.warn(`API|Join Corp: Could not generate corp`);

        return null;
      }

      return { ...resp, TransactionId };
    });
  },

  /**
   *
   */
  async createAbandonedCorpEmailTokenVerify(companyEmail, otpValue) {
    const endpoint = `${this.emailTokenVerifyBasePath}?email=${companyEmail}&token=${otpValue}`;
    const TransactionId = uuidv4();

    return ApiClient._post(endpoint).then((resp) => {
      if (!resp.data) {
        logger.warn(`API|Join Corp: Could not generate corp`);

        return null;
      }

      return { ...resp, TransactionId };
    });
  },

  /**
   *
   */
  async resendAbandonedCorpEmailTokenVerify(companyEmail) {
    const endpoint = `${this.emailResendBasePath}?email=${companyEmail}`;
    const TransactionId = uuidv4();

    return ApiClient._post(endpoint).then((resp) => {
      if (!resp.data) {
        logger.warn(`API|Join Corp: Could not generate corp`);

        return null;
      }

      return { ...resp, TransactionId };
    });
  },

  /**
   *
   */
  async verifyDomain(corpEmail, facilityId) {
    const endpoint = `${this.verifyDomainPath}?email=${corpEmail}&facilityId=${facilityId}`;
    const TransactionId = uuidv4();

    return ApiClient._get(endpoint)
      .then((resp) => {
        if (!resp.data) {
          logger.warn(
            `API|Join Corp: An error occurred while trying to verify the domain`
          );

          return null;
        }

        return { ...resp, TransactionId };
      })
      .catch(() => {
        return {
          domainVerified: false,
        };
      });
  },

  /**
   *
   */
  updateAbandonedCorpLead({
    FacilityId,
    meetingPurpose,
    newsletter,
    OutreachCode,
    PreferredCommunicationMethod,
    PreferredTimeOfDay,
    ScheduledVisitTime,
    TransactionId,
  }) {
    return ApiClient._put(this.corpAbandonedPath, {
      data: {
        Answers: [
          {
            additionalInfo: "",
            answerId: newsletter
              ? NEWSLETTER_SUBSCRIBE_ANSWER_ID
              : NEWSLETTER_DECLINE_ANSWER_ID,
          },
        ],
        FacilityId,
        OutreachCode,
        PreferredCommunicationMethod,
        PreferredTimeOfDay,
        ScheduledVisitTime,
        TransactionId,
        meetingPurpose,
      },
    }).then((resp) => resp.data.tokenId);
  },

  /**
   *
   */
  updateCorpLead({ answers, facilityId, leadTokenId, scheduledVisitTime }) {
    //The corp endpoint doesn't exist, it should be created in the backend.
    //For now, we can use standard lead endpoint.
    const endpoint = "/v6/acq/lead";

    return ApiClient._put(endpoint, {
      data: {
        TokenId: leadTokenId,
        ...(answers?.length > 0 && { Answers: [...answers] }),
        ...(facilityId && { FacilityId: facilityId }),
        ...(scheduledVisitTime && { ScheduledVisitTime: scheduledVisitTime }),
      },
    });
  },
};

export default JoinCorp;
