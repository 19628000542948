import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./ChangeClubButton.module.scss";

const ChangeClubButton = ({ children, className, handleClick }) => {
  return (
    <button
      className={classNames(styles.changeClubButton, className)}
      name="JoinFlow:ChangeClubButton - Change Club"
      onClick={handleClick}
      type="button"
    >
      {children || "Change Club"}
    </button>
  );
};

ChangeClubButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

export default ChangeClubButton;
