export const STEPS = {
  appointmentConfirmation: 2,
  contactInformation: 0,
  scheduleAppointment: 1,
};

// Descibes the state structure for the lead flow
export const INITIAL_STATE = {
  contactInput: null,
  createdDate: new Date().getTime(),
  hasCreatedLead: false,
  isInEditMode: false,
  isPromo: false,
  leadFlowIsVisible: true,
  personalizeOptions: [],
  personalizeOptionsStep: 1,
  receiveTextAlerts: false,
  scheduledFacility: null, // when user submits/skips appointment for a club (or submits contact info for non-BYOA club) (final selection)
  selectedAppointment: "", // ISO string without UTC designator (Z)
  selectedFacility: null, // when user selects club from modal or submits contact form for BYOA club but hasn't scheduled/skipped appointment (active selection)
  showGuidedWorkouts: false,
  step: STEPS.contactInformation,
  submittedAppointment: null,
  subscribeToNewsletter: false,
  suggestedFacility: null, // the facility passed to the lead flow form e.g.: by visiting a club detail page (passive selection)
};
