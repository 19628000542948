import { format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import React from "react";

import MapIcon from "@/assets/icons/map-icon.svg";
import RightCaret from "@/assets/icons/right-caret-grey.svg";
import AddToCalendarButton from "@/components/AddToCalendarButton";
import { CLUBS } from "@/constants";

import styles from "./AppointmentConfirmation.module.scss";

const buildGoogleMapUrl = (lat, long) => {
  const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;

  return googleMapUrl;
};

const AppointmentConfirmation = ({
  appointment,
  appointmentType = "Appointment",
  facility,
  onEditAppointment,
}) => {
  const { facilityId, isAcceptingAppointments, webName } = facility;
  const { address, city, lat, long, state, timeZone, zip } =
    CLUBS.byId[facilityId];
  const gMapURL = buildGoogleMapUrl(lat, long);

  return (
    <div>
      {appointment && (
        <>
          <time dateTime={appointment}>
            {formatDate(appointment, "EEEE, MMMM d 'at' h:mm a")}
          </time>

          <a
            className={styles.clubNameLink}
            href={gMapURL}
            rel="noreferrer"
            target="_blank"
          >
            <MapIcon />
            {webName}
          </a>

          <address className={styles.addressBlock}>
            <p className={styles.addressText}>{address}</p>
            <p className={styles.addressText}>
              {city}, {state} {zip}
            </p>
          </address>

          <button
            className={styles.editAppointment}
            name={`LeadFlow:Steps:Confirmation:AppointmentConfirmation - Update ${appointmentType}`}
            onClick={onEditAppointment}
          >
            Update {appointmentType}
            <RightCaret />
          </button>

          <AddToCalendarButton
            className={styles.addToCalendarButton}
            duration={30}
            location={`${address} ${city} ${state} ${zip}`}
            name={`Scheduled ${appointmentType} at Equinox ${webName}`}
            startDate={formatDate(new Date(appointment), "yyyy-MM-dd")}
            startTime={appointment.split("T")[1]}
            timeZone={timeZone}
          >
            Add to calendar
          </AddToCalendarButton>
        </>
      )}

      {!appointment &&
        (isAcceptingAppointments ? (
          <>
            We’ve received your information and a membership advisor from{" "}
            <strong>{webName}</strong> will be in contact shortly.
          </>
        ) : (
          <>
            Thank you for your interest in <strong>{webName}</strong>. A
            membership advisor will be reaching out shortly.
          </>
        ))}
    </div>
  );
};

AppointmentConfirmation.propTypes = {
  appointment: PropTypes.string,
  appointmentType: PropTypes.string,
  facility: PropTypes.object.isRequired,
  onEditAppointment: PropTypes.func,
};

export default AppointmentConfirmation;
