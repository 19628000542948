import { useCallback, useEffect, useRef, useState } from "react";

import FacilitiesApiClient from "@/api/client/entities/facilities";
import { getFacilitiesData } from "@/api/facilities";
import { SESSION_STORAGE_KEYS } from "@/constants";
import useLocation from "@/hooks/useLocation";

const CACHE_TTL = 3600000;

/**
 *
 */
const useNearbyClubs = () => {
  const {
    isLoading: isLoadingLocation,
    latitude,
    longitude,
    updateLocation,
  } = useLocation();
  const isFetching = useRef(false);
  const [state, setState] = useState({
    isLoading: true,
    nearbyClubs: [],
    timestamp: null,
  });

  /**
   *
   */
  const getFacilityIds = useCallback(async (latitude, longitude) => {
    const facilityIds = await FacilitiesApiClient.findByLatLon(
      latitude,
      longitude
    );

    return facilityIds;
  }, []);

  /**
   *
   */
  const getNearbyClubs = useCallback(
    async (latitude, longitude) => {
      if (isFetching.current) {
        return;
      }

      isFetching.current = true;

      const timestamp = new Date().getTime();

      let facilityIds;
      let nearbyClubs = [];

      if (latitude && longitude) {
        if (!isLoadingLocation) {
          updateLocation(latitude, longitude);
        }

        facilityIds = await getFacilityIds(latitude, longitude);
      }

      if (facilityIds) {
        nearbyClubs = await getFacilitiesData(facilityIds);

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.NEARBY_CLUBS,
          JSON.stringify({
            latitude,
            longitude,
            nearbyClubs,
            timestamp,
          })
        );
      }

      setState({
        isLoading: false,
        nearbyClubs,
        timestamp,
      });

      isFetching.current = false;
    },
    [getFacilityIds, isLoadingLocation, updateLocation]
  );

  /**
   *
   */
  useEffect(() => {
    const cachedNearbyClubs = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.NEARBY_CLUBS)
    );

    if (cachedNearbyClubs?.timestamp) {
      if (
        new Date().getTime() - cachedNearbyClubs.timestamp <= CACHE_TTL &&
        latitude === cachedNearbyClubs.latitude &&
        longitude === cachedNearbyClubs.longitude
      ) {
        setState({
          ...cachedNearbyClubs,
          isLoading: false,
        });

        return;
      }
    }

    getNearbyClubs(latitude, longitude);
  }, [getNearbyClubs, latitude, longitude]);

  return {
    ...state,
    getNearbyClubs,
  };
};

export default useNearbyClubs;
