import debounce from "lodash/debounce";
import React, { Component } from "react";

import { TIMINGS } from "@/constants";

export default function withScreenDimensions(WrappedComponent) {
  return class ScreenDimensions extends Component {
    constructor(props) {
      super(props);

      this.debounceHandleResize = debounce(
        this.updateWindowDimensions,
        TIMINGS.THROTTLE_RESIZE_TIME_OUT
      );
    }

    state = {
      height: 0,
      isDesktop: false,
      isMobile: true,
      isTablet: false,
      width: 0,
    };

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.debounceHandleResize);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.debounceHandleResize);
    }

    updateWindowDimensions = () => {
      const isMobile = window.innerWidth <= 650;
      const isTablet = window.innerWidth >= 651 && window.innerWidth <= 1023;
      const isDesktop = window.innerWidth >= 1024;

      this.setState({
        height: window.innerHeight,
        isDesktop,
        isMobile,
        isTablet,
        width: window.innerWidth,
      });
    };

    render() {
      const { width, height, isMobile, isTablet, isDesktop } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          isDesktop={isDesktop}
          isMobile={isMobile}
          isTablet={isTablet}
          windowHeight={height}
          windowWidth={width}
        />
      );
    }
  };
}
