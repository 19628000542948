import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import classNames from "classnames";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { ACDL_LEAD_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";

import { personalizeQuiz } from "../config";
import { collateForAdobeDataLayer } from "../utils";
import styles from "./PersonalizeQuiz.module.scss";

const hasOption = (optionsArr, answer) =>
  !!optionsArr.find((option) => option.answerId === answer.id);

const renderQuizItems = (
  quizItems,
  clickHandler,
  currentOptionsArr,
  allowMultiple,
  setOtherTextValue, // handler for the textarea input change
  otherTextValue // value for the "Other" option textarea
) => {
  if (!quizItems) {
    return [];
  }

  const ButtonComponent = allowMultiple ? Checkbox : Radio;

  return (
    <div>
      {quizItems.map((quizItem) => {
        const isOther = quizItem.name === "Other"; // Check if it's the 'Other' option

        return (
          <div
            className={classNames(
              styles.quizItemContainer,
              isOther && styles.otherOptionContainer
            )}
            data-context={quizItem.context}
            key={quizItem.name}
          >
            <FormControlLabel
              className={styles.quizItem}
              control={
                <ButtonComponent
                  checked={hasOption(currentOptionsArr, quizItem)}
                  className={styles.quizItemCheckbox}
                />
              }
              label={quizItem.name}
              labelPlacement="end"
              onClick={(e) => clickHandler(e, quizItem)}
            />
            {/* Conditionally render a textarea for the 'Other' option */}
            {isOther && hasOption(currentOptionsArr, quizItem) && (
              <textarea
                className={styles.otherTextarea}
                onChange={(e) => setOtherTextValue(e.target.value)}
                placeholder="Let us know why."
                value={otherTextValue} // Controlled value from state
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

const PersonalizeQuiz = ({
  onCtaClick,
  personalizeOptionsStep = 1,
  setNewDataLayer,
}) => {
  const lastQuestionIndex = personalizeQuiz.length - 1;

  const [collatedAnsLength, setCollatedAnsLength] = useState([]);

  const [currentOptionsArr, setCurrentOptionsArr] = useState([]);

  const [otherTextValue, setOtherTextValue] = useState("");

  const { heading, subText, content, ctaText, allowMultiple } =
    personalizeQuiz[personalizeOptionsStep];

  const router = useRouter();
  const ctaIsEnabled =
    content?.some((item) =>
      currentOptionsArr.some((option) => option.answerId === item.id)
    ) || personalizeOptionsStep === lastQuestionIndex; // Last Question

  /**
   *
   */
  const handleQuizItemClick = (e, option) => {
    e.preventDefault();

    // Update currentOptionsArr based on whether the checkbox is checked or not
    setCurrentOptionsArr((prevArr) => {
      let newOptions;

      if (!hasOption(currentOptionsArr, option)) {
        newOptions = allowMultiple
          ? [...prevArr, { answerId: option.id, name: option.name }]
          : [
              ...prevArr.filter((item) => {
                const itemIsQuestionGroup = !!content.find(
                  (contentItem) => contentItem.id === item.answerId
                );

                return !itemIsQuestionGroup;
              }), // Remove previous selection for this question only
              { answerId: option.id, name: option.name }, // Add the new selection
            ];
      } else {
        newOptions = prevArr.filter((item) => item.answerId !== option.id);
      }

      return newOptions; // Return updated state
    });
  };

  /**
   *
   */
  const handleCtaClick = async () => {
    const collatedAnsRes = collateForAdobeDataLayer(
      currentOptionsArr,
      personalizeOptionsStep,
      personalizeQuiz[personalizeOptionsStep].trackingName,
      collatedAnsLength
    );
    const currentCollated = collatedAnsLength;
    currentCollated.push(collatedAnsRes.length);
    setCollatedAnsLength(currentCollated);

    setNewDataLayer(
      ACDL_LEAD_FLOW_TRACKING.leadFlowSurvey(
        personalizeQuiz[personalizeOptionsStep].trackingName,
        collatedAnsRes
      )
    );

    if (personalizeOptionsStep < lastQuestionIndex) {
      if (personalizeQuiz[personalizeOptionsStep + 1].trackingName) {
        setNewDataLayer(
          ACDL_LEAD_FLOW_TRACKING.surveyView(
            router.asPath,
            personalizeQuiz[personalizeOptionsStep + 1].trackingName
          )
        );
      }

      if (otherTextValue && personalizeOptionsStep === 1) {
        const otherOptionIndex = currentOptionsArr.findIndex(
          (option) => option.answerId === "94"
        );

        if (otherOptionIndex !== -1) {
          currentOptionsArr[otherOptionIndex] = {
            ...currentOptionsArr[otherOptionIndex],
            name: otherTextValue,
          };
        }
      }

      onCtaClick(currentOptionsArr, personalizeOptionsStep + 1);
    } else {
      // Go to members benefits page
      router.push("/memberbenefits");
    }
  };

  /**
   *
   */
  useEffect(() => {
    setNewDataLayer(
      ACDL_LEAD_FLOW_TRACKING.surveyView(
        router.asPath,
        personalizeQuiz[1].trackingName
      )
    );
  }, [router.asPath, setNewDataLayer]);

  return (
    <div
      aria-expanded={personalizeOptionsStep > 0}
      className={styles.personalizeVisit}
    >
      <h4 className={styles.headingText}>{heading}</h4>
      <p className={styles.subHeadingText}>
        {subText}
        {personalizeOptionsStep < lastQuestionIndex && (
          <span>{`${personalizeOptionsStep}/${lastQuestionIndex - 1}`}</span>
        )}
      </p>
      {renderQuizItems(
        content,
        handleQuizItemClick,
        currentOptionsArr,
        allowMultiple,
        setOtherTextValue,
        otherTextValue
      )}
      <div
        className={classNames({
          [styles.ctaButtonContainer]: personalizeOptionsStep !== 5,
        })}
      >
        <button
          className={styles.ctaButton}
          disabled={!ctaIsEnabled}
          name="LeadFlow:PersonalizeVisit:PersonalizeQuiz"
          onClick={handleCtaClick}
        >
          {ctaText}
        </button>
      </div>
    </div>
  );
};

PersonalizeQuiz.propTypes = {
  onCtaClick: PropTypes.func,
  personalizeOptionsStep: PropTypes.number,
  setNewDataLayer: PropTypes.func,
};

export default PersonalizeQuiz;
