import logger from "../../../utils/logger";
import ApiClient from "../client";

const JoinConfirmationEmail = {
  namespace: "joinConfirmationEmail",

  basePath: "/v6/acq/residential/thankyouemail",

  async sendConfirmationEmail(barcode, fullName, facilityId, email) {
    const endpoint = `${this.basePath}/${barcode}/${fullName}/${facilityId}/${email}`;

    return ApiClient._get(endpoint).then((resp) => {
      if (!resp.data.success) {
        logger.warn(
          `API| cannot send membership confirmation email for barcode:${barcode}`
        );

        return null;
      }
    });
  },
};

export default JoinConfirmationEmail;
