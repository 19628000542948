import PropTypes from "prop-types";
import React from "react";

import { ctaUnderline } from "./index.module.scss";

const handleCTAClick = (url, newTab) => {
  if (!url) {
    return;
  }

  if (newTab) {
    return window.open(url, "_blank");
  }

  return (window.location = url);
};

const SecondaryCta = ({
  label,
  tabIndex,
  style,
  url,
  newTab,
  onClick,
  disabled = false,
  type = "button",
}) => {
  const ctaStyle = style ? `${ctaUnderline} ${style}` : `${ctaUnderline}`;

  return (
    <button
      aria-label={label}
      className={ctaStyle}
      disabled={disabled}
      name="Buttons:SecondaryCTA"
      onClick={
        onClick ||
        (() => {
          handleCTAClick(url, newTab);
        })
      }
      tabIndex={tabIndex}
      title={label}
      type={type}
    >
      <span>{label}</span>
    </button>
  );
};

SecondaryCta.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.any,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  url: PropTypes.string,
};

export default SecondaryCta;
