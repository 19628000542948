export const modifyOutreachCodeForVwoCampaign = (outreachCode) => {
  const campaigns = [];

  for (const data in document?.body?.dataset) {
    if (data?.startsWith("vwoWeb")) {
      campaigns.push(
        [
          data.slice(3).toUpperCase(),
          ...(document.body.dataset[data]?.split(":") || []),
        ].join("-")
      );
    }
  }

  if (campaigns.length > 0) {
    return `${outreachCode}_${campaigns.join("_")}`;
  }

  return outreachCode;
};
