import { StyledEngineProvider } from "@mui/material/styles";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import slugify from "slugify";

import { baseEnvironmentConfig } from "@/api/constants";
import Referrals from "@/api/equifit/referrals";
import {
  getFacilityData,
  getFacilityDetailsData,
  getFacilityGallerySlides,
} from "@/api/facilities";
import Loader from "@/components/Loader";
import { ACDL_LEAD_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { PROSPECT } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import {
  ClubSelectorPanel,
  ExitFlowConfirmation,
  Headline,
} from "@/features/user-flow";
import useActivityCount from "@/hooks/useActivityCount";
import useNearbyClubs from "@/hooks/useNearbyClubs";
import dynamicContentReplacer from "@/utils/helpers/dynamic_content_replacer";

import LeadFlowApi from "../api";
import { INITIAL_STATE, STEPS } from "../constants";
import {
  getStoredState,
  handleAdobeTracking,
  isClubAcceptingAppointments,
  updateStoredState,
} from "../utils";
import AppointmentConfirmation from "./AppointmentConfirmation";
import ClubGallery from "./ClubGallery";
import ContactInformation from "./ContactInformation";
import styles from "./LeadFlow.module.scss";
import PersonalizeQuiz from "./PersonalizeQuiz";
import ScheduleAppointment from "./ScheduleAppointment";

const LeadFlow = ({
  ctaEvent,
  data: contentfulData = {},
  facility = null,
  isClubDetail,
  isPromo,
  leadViewDataValue,
  page,
  referringId,
}) => {
  const { decrementActivityCount, incrementActivityCount, isActive } =
    useActivityCount();
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);
  const { isLoading: isLoadingNearbyClubs, nearbyClubs } = useNearbyClubs();
  const router = useRouter();
  const storedState = getStoredState();
  const [errorMessage, setErrorMessage] = useState("");
  const [formState, setFormState] = useState(
    storedState ? storedState : { ...INITIAL_STATE }
  );
  const [showClubSelector, setShowClubSelector] = useState(false);
  const [visitAppointments, setVisitAppointments] = useState([]);
  const [workoutAppointments, setWorkoutAppointments] = useState([]);
  const [gallerySlides, setGallerySlides] = useState(null);

  const {
    isInEditMode,
    leadTokenId,
    personalizeOptionsStep,
    previousStep,
    scheduledFacility,
    selectedFacility,
    showGuidedWorkouts,
    step,
    submittedAppointment,
    suggestedFacility,
  } = formState;

  const currentFacility =
    selectedFacility || scheduledFacility || suggestedFacility;
  const showGuidedWorkoutCta =
    router.pathname === "/membership" && currentFacility?.hasGuidedWorkouts;

  let headlineData;

  if (contentfulData?.headlines?.length > 0) {
    headlineData =
      contentfulData?.headlines?.[
        Math.min(step, contentfulData?.headlines?.length - 1)
      ];
  } else {
    switch (step) {
      case STEPS.contactInformation:
        headlineData = {
          fields: {
            ...contentfulData?.headlineContactInformation?.fields,
          },
        };

        if (
          headlineData?.fields?.subcopy &&
          step === STEPS.contactInformation &&
          showGuidedWorkoutCta
        ) {
          headlineData.fields.subcopy =
            headlineData?.fields?.subcopy +
            "\n\nWe are a members only club. We invite you to take a guided tour or a complimentary workout.";
        }

        break;

      case STEPS.scheduleAppointment:
        headlineData = showGuidedWorkouts
          ? contentfulData?.headlineScheduleWorkout
          : contentfulData?.headlineScheduleVisit;

        break;

      case STEPS.appointmentConfirmation:
        headlineData = contentfulData?.headlineConfirmation;
        break;
    }
  }

  const isEmbedded =
    isClubDetail ||
    isPromo ||
    router.pathname === "/" ||
    ["member_benefits", "personal-training", "pilates", "referrals"].includes(
      page
    );

  const showScheduleVisitByURLs =
    router.pathname === "/inquiry/schedule-your-visit" ||
    router.pathname === "/membership" ||
    router.pathname?.indexOf("/membership/") === 0;

  const needsRedirect =
    step === STEPS.scheduleAppointment &&
    currentFacility?.facilityId &&
    !showScheduleVisitByURLs &&
    !referringId;

  /**
   *
   */
  const skipVisit = useCallback(async () => {
    incrementActivityCount();

    setNewDataLayer(
      ACDL_LEAD_FLOW_TRACKING.confirmAVisit("Skip selecting date", null, !null)
    );

    const nextState = {
      ...formState,
      scheduledFacility: currentFacility,
      step: STEPS.appointmentConfirmation,
    };

    if (!submittedAppointment && !formState.referringId) {
      const leadTokenId = await LeadFlowApi.updateAbandonedLead(nextState);
      nextState.leadTokenId = leadTokenId;
    } else if (formState.referringId) {
      await LeadFlowApi.updateLead(formState);
    }

    setFormState(nextState);

    decrementActivityCount();
  }, [
    currentFacility,
    formState,
    submittedAppointment,
    setNewDataLayer,
    setFormState,
    incrementActivityCount,
    decrementActivityCount,
  ]);

  /**
   *
   */
  const getVisitAppointments = useCallback(
    async (facilityId) => {
      if (facilityId) {
        const appointments =
          await LeadFlowApi.getOpenLeadTimesFromFacilityId(facilityId);
        const shouldSkipVisit =
          appointments?.length === 0 && step === STEPS.scheduleAppointment;

        if (shouldSkipVisit) {
          skipVisit();
        } else {
          setVisitAppointments(appointments);
        }
      } else {
        setVisitAppointments([]);
      }
    },
    [skipVisit, step]
  );

  /**
   *
   */
  const getWorkoutAppointments = async (facilityId) => {
    if (facilityId) {
      const appointments =
        await LeadFlowApi.fetchWorkoutAppointments(facilityId);
      setWorkoutAppointments(appointments);
    } else {
      setWorkoutAppointments([]);
    }
  };

  const getGallerySlides = async (facilityId) => {
    if (facilityId) {
      const slides = await getFacilityGallerySlides(facilityId);
      setGallerySlides(slides);
    } else {
      setGallerySlides([]);
    }
  };

  /**
   *
   */
  const handleChangeClub = async (club) => {
    incrementActivityCount();

    const newState = {
      ...formState,
      showGuidedWorkouts: false,
    };

    const isAcceptingAppointments = isClubAcceptingAppointments(
      club,
      formState.config
    );

    const facilityDetails = await getFacilityDetailsData(club.facilityId);

    if (isAcceptingAppointments) {
      newState.selectedFacility = {
        ...facilityDetails,
        ...club,
        isAcceptingAppointments,
      };
    } else {
      if (step === STEPS.scheduleAppointment) {
        newState.scheduledFacility = {
          ...facilityDetails,
          ...club,
          isAcceptingAppointments,
        };

        newState.step = STEPS.appointmentConfirmation;
      } else {
        newState.selectedFacility = {
          ...facilityDetails,
          ...club,
          isAcceptingAppointments,
        };
      }

      if (formState.hasCreatedLead) {
        const leadTokenId = await LeadFlowApi.updateAbandonedLead({
          ...formState,
          club,
        });

        newState.leadTokenId = leadTokenId;
      }
    }

    setFormState(newState);

    setShowClubSelector(false);

    handleAdobeTracking(
      {
        ...facilityDetails,
        ...club,
      },
      setNewDataLayer
    );

    decrementActivityCount();
  };

  /**
   *
   */
  const handleClubEdit = () => {
    setShowClubSelector(true);
  };

  /**
   *
   */
  const handleClubSelectorClose = () => {
    setShowClubSelector(false);
  };

  /**
   *
   */
  const handleEditAppointment = () => {
    setFormState({
      ...formState,
      isInEditMode: true,
      step: previousStep,
    });
  };

  /**
   *
   */
  const handleGuidedWorkoutClick = async (formValues) => {
    incrementActivityCount();

    const newState = {
      ...formState,
      contactInput: {
        ...formValues,
      },
    };

    setFormState(newState);

    const leadApiResult = newState?.leadTransactionId
      ? await LeadFlowApi.updateAbandonedLead({
          ...newState,
          leadTransactionId: newState.leadTransactionId,
        })
      : await LeadFlowApi.createNewLead(
          newState,
          setNewDataLayer,
          router,
          isEmbedded
        );

    if (leadApiResult) {
      if (typeof currentFacility?.isAcceptingAppointments !== "boolean") {
        currentFacility.isAcceptingAppointments = isClubAcceptingAppointments(
          currentFacility,
          formState.config
        );
      }

      if (currentFacility?.isAcceptingAppointments) {
        newState.selectedFacility = currentFacility;
        newState.showGuidedWorkouts = true;

        const appointments = await LeadFlowApi.fetchWorkoutAppointments(
          currentFacility?.facilityId
        );

        if (appointments.length >= 0) {
          newState.step = STEPS.scheduleAppointment;
          setWorkoutAppointments(appointments);
        } else {
          setErrorMessage("An error has occurred.");
        }
      } else {
        newState.scheduledFacility = currentFacility;
        newState.step = STEPS.appointmentConfirmation;

        if (!referringId) {
          const leadTokenId = await LeadFlowApi.updateAbandonedLead({
            ...newState,
            leadTransactionId: newState?.leadTransactionId
              ? newState.leadTransactionId
              : leadApiResult.leadTransactionId,
          });

          if (leadTokenId) {
            newState.leadTokenId = leadTokenId;
          }
        }
      }

      if (leadApiResult?.associationToken) {
        newState.leadTokenId = leadApiResult?.associationToken;
      }

      newState.hasCreatedLead = true;

      newState.leadTransactionId = formState?.referrerDetails
        ? leadApiResult.associationToken
        : leadApiResult.leadTransactionId;
    } else {
      setErrorMessage(
        "An error has occurred. If you've just shared your email address with us, please wait a bit before trying again, or use a different one."
      );
    }

    setFormState(newState);

    decrementActivityCount();
  };

  /**
   *
   */
  const handleQuizCtaClick = async (
    personalizeOptions,
    nextPersonalizeOptionsStep
  ) => {
    incrementActivityCount();

    await LeadFlowApi.updateLead({
      leadTokenId,
      personalizeOptions,
      scheduledFacility: currentFacility,
    });

    setFormState({
      ...formState,
      personalizeOptions,
      personalizeOptionsStep: nextPersonalizeOptionsStep,
    });

    decrementActivityCount();
  };

  /**
   *
   */
  const handleVisitSchedule = async (selectedAppointment) => {
    const selectedVisitTime = selectedAppointment;
    const selectedVisitHour = selectedAppointment.split("T")[1];

    incrementActivityCount();

    if (submittedAppointment || referringId || storedState?.referringId) {
      // ACDL Confirm A Visit Event
      setNewDataLayer(
        ACDL_LEAD_FLOW_TRACKING.confirmAVisit(
          selectedVisitTime,
          selectedVisitHour,
          !selectedVisitHour
        )
      );

      const results = await LeadFlowApi.updateLead(
        {
          leadTokenId,
          scheduledFacility: currentFacility,
          scheduledVisitTime: selectedVisitTime,
        },
        setNewDataLayer
      );

      if (!results) {
        alert("An error has occurred.");
      }

      setFormState({
        ...formState,
        previousStep: STEPS.scheduleAppointment,
        scheduledFacility: currentFacility,
        scheduledVisitHour: selectedVisitHour,
        scheduledVisitTime: selectedVisitTime,
        step: STEPS.appointmentConfirmation,
        submittedAppointment: selectedAppointment,
      });
    } else {
      const leadTokenId = await LeadFlowApi.updateAbandonedLead({
        ...formState,
        meetingPurpose: PROSPECT.MEETING_PURPOSES.TOUR,
        scheduledVisitTime: selectedVisitTime,
      });

      setFormState({
        ...formState,
        leadTokenId,
        meetingPurpose: PROSPECT.MEETING_PURPOSES.TOUR,
        previousStep: STEPS.scheduleAppointment,
        scheduledFacility: currentFacility,
        scheduledVisitHour: selectedVisitHour,
        scheduledVisitTime: selectedVisitTime,
        step: STEPS.appointmentConfirmation,
        submittedAppointment: selectedAppointment,
      });

      setNewDataLayer(
        ACDL_LEAD_FLOW_TRACKING.confirmAVisit(
          selectedVisitTime,
          selectedVisitHour,
          !selectedVisitHour
        )
      );
    }

    decrementActivityCount();
  };

  /**
   *
   */
  const handleVisitSkip = useCallback(async () => {
    await skipVisit();
  }, [skipVisit]);

  /**
   *
   */
  const handleWorkoutSchedule = async (selectedAppointment) => {
    const selectedVisitHour = selectedAppointment.split("T")[1];

    incrementActivityCount();

    if (submittedAppointment || referringId) {
      // ACDL Confirm A Visit Event
      setNewDataLayer(
        ACDL_LEAD_FLOW_TRACKING.confirmAVisit(
          selectedAppointment,
          selectedVisitHour,
          !selectedVisitHour
        )
      );

      const results = await LeadFlowApi.updateLead(
        {
          leadTokenId,
          scheduledFacility,
          scheduledVisitTime: selectedAppointment,
          selectedFacility: currentFacility,
        },
        setNewDataLayer
      );

      if (!results) {
        alert("An error has occurred.");
      }

      setFormState({
        ...formState,
        previousStep: STEPS.scheduleAppointment,
        scheduledFacility: currentFacility,
        scheduledVisitHour: selectedVisitHour,
        scheduledVisitTime: selectedAppointment,
        step: STEPS.appointmentConfirmation,
        submittedAppointment: selectedAppointment,
      });
    } else {
      const leadTokenId = await LeadFlowApi.updateAbandonedLead({
        ...formState,
        meetingPurpose: PROSPECT.MEETING_PURPOSES.WORKOUT,
        scheduledVisitTime: selectedAppointment,
      });

      setFormState({
        ...formState,
        leadTokenId,
        meetingPurpose: PROSPECT.MEETING_PURPOSES.WORKOUT,
        previousStep: STEPS.scheduleAppointment,
        scheduledFacility: currentFacility,
        scheduledVisitHour: selectedVisitHour,
        scheduledVisitTime: selectedAppointment,
        step: STEPS.appointmentConfirmation,
        submittedAppointment: selectedAppointment,
      });

      setNewDataLayer(
        ACDL_LEAD_FLOW_TRACKING.confirmAVisit(
          selectedAppointment,
          selectedVisitHour,
          !selectedVisitHour
        )
      );
    }

    decrementActivityCount();
  };

  /**
   *
   */
  const handleWorkoutSkip = async () => {
    incrementActivityCount();

    setNewDataLayer(
      ACDL_LEAD_FLOW_TRACKING.confirmAWorkout(
        "Skip selecting date",
        null,
        !null
      )
    );

    const nextState = {
      ...formState,
      scheduledFacility: currentFacility,
      step: STEPS.appointmentConfirmation,
    };

    if (!submittedAppointment && !referringId) {
      const leadTokenId = await LeadFlowApi.updateAbandonedLead(nextState);
      nextState.leadTokenId = leadTokenId;
    } else if (referringId) {
      await LeadFlowApi.updateLead(formState);
    }

    setFormState(nextState);

    decrementActivityCount();
  };

  const sectionSlug = slugify(
    contentfulData?.subNavLabel || "Lead Flow"
  ).toLowerCase();

  const analyticsInfo = `${
    !isEmbedded ? "lead-flow-button-overlay" : "lead-flow-embedded"
  }, ${isPromo ? "lead-flow-schedule" : "lead-flow-contact"}`;

  const title =
    referringId && formState?.referrerDetails?.firstName
      ? dynamicContentReplacer(headlineData?.fields.title, {
          "{{FIRST_NAME}}": formState?.referrerDetails?.firstName,
        })
      : headlineData?.fields.title;

  /**
   *
   */
  useEffect(() => {
    !referringId && updateStoredState(formState);
  }, [formState, referringId]);

  /**
   * in case of referral lead flow , calls memberDetails API
   */
  useEffect(() => {
    if (referringId) {
      const { config, joinMarketingCode, marketingCode } = contentfulData;

      incrementActivityCount();

      Referrals.getMemberDetails(referringId)
        .then((response) => {
          setFormState({
            ...formState,
            config,
            contentfulData,
            joinOutreachCode: joinMarketingCode,
            outreachCode: marketingCode,
            referrerDetails: response,
            referringId,
          });
        })
        .finally(() => {
          decrementActivityCount();
        });
    }
  }, [referringId]); // eslint-disable-line react-hooks/exhaustive-deps -- WEB-2739

  /**
   *
   */
  useEffect(() => {
    const initLeadFlow = async () => {
      incrementActivityCount();

      const storedState = getStoredState();
      const { config, joinMarketingCode, marketingCode } = contentfulData;

      const newState = {
        ...(storedState || INITIAL_STATE),
      };

      // handle redirect from '/join/alumni'
      const currentUrl = new URL(window.location.href);
      const urlParams = new URLSearchParams(currentUrl.search);

      if (urlParams.has("firstName")) {
        newState.redirectFromFormerMember = true;
        newState.contactInput = {};
        newState.contactInput["First Name"] = urlParams.get("firstName");
        newState.contactInput["Last Name"] = urlParams.get("lastName");
        newState.contactInput["Email Address"] = urlParams.get("email");
      }

      newState.config = config;
      newState.contentfulData = contentfulData;
      newState.isPromo = isPromo;
      newState.joinOutreachCode = joinMarketingCode;
      newState.outreachCode = marketingCode;

      let facilityData;

      if (facility?.facilityId) {
        facilityData = await getFacilityData(facility?.facilityId);

        newState.suggestedFacility = {
          ...facility,
          ...facilityData,
          isAcceptingAppointments: isClubAcceptingAppointments(
            facilityData,
            config
          ),
        };
      }

      if (
        router.pathname !== "/inquiry/schedule-your-visit" &&
        router.pathname !== "/membership" &&
        !isEmbedded
      ) {
        setNewDataLayer(
          ACDL_LEAD_FLOW_TRACKING.leadView(router.asPath, {
            ...facility,
            ...facilityData,
          })
        );
      }

      setFormState(newState);

      decrementActivityCount();
    };

    initLeadFlow();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- WEB-2321

  /**
   *
   */
  useEffect(() => {
    const getNearbyClubDetails = async (nearbyClub) => {
      incrementActivityCount();

      const facilityDetails = await getFacilityDetailsData(
        nearbyClub.facilityId
      );

      setFormState({
        ...formState,
        suggestedFacility: {
          ...facilityDetails,
          ...nearbyClub,
          isAcceptingAppointments: isClubAcceptingAppointments(
            nearbyClub,
            contentfulData?.config
          ),
        },
      });

      decrementActivityCount();
    };

    if (
      !formState.suggestedFacility &&
      !isLoadingNearbyClubs &&
      nearbyClubs?.length > 0
    ) {
      getNearbyClubDetails(nearbyClubs[0]);
    }
  }, [
    contentfulData?.config,
    decrementActivityCount,
    formState,
    incrementActivityCount,
    isLoadingNearbyClubs,
    nearbyClubs,
  ]);

  /**
   *
   */
  useEffect(() => {
    if (needsRedirect) {
      router.push("/inquiry/schedule-your-visit");
    } else if (step !== STEPS.contactInformation && !isEmbedded) {
      document?.querySelector(`.${styles.leadFlow}`)?.scrollIntoView(true);
      document?.querySelector(`.${styles.formContent}`)?.scroll(0, 0);
    }
  }, [isEmbedded, personalizeOptionsStep, needsRedirect, router, step]);

  /**
   *
   */
  useEffect(() => {
    if (step === STEPS.scheduleAppointment) {
      if (showGuidedWorkouts) {
        getWorkoutAppointments(currentFacility?.facilityId);
      } else {
        getVisitAppointments(currentFacility?.facilityId);
      }
    }
  }, [
    currentFacility?.facilityId,
    showGuidedWorkouts,
    step,
    getVisitAppointments,
  ]);

  useEffect(() => {
    if (
      step === STEPS.appointmentConfirmation &&
      personalizeOptionsStep === 5
    ) {
      getGallerySlides(currentFacility?.facilityId);
    }
  }, [step, personalizeOptionsStep, currentFacility?.facilityId]);

  return (
    <StyledEngineProvider injectFirst>
      <section
        className={styles.leadFlow}
        data-analytics={analyticsInfo}
        data-is="LeadFlow"
        data-leadview={leadViewDataValue}
        data-testid={baseEnvironmentConfig.testIds.leadFlow}
        id={sectionSlug}
      >
        <ClubSelectorPanel
          isOpen={showClubSelector}
          onChangeClub={handleChangeClub}
          onClose={handleClubSelectorClose}
        />

        {headlineData && (
          <Headline
            className={styles.headline}
            finePrint={headlineData?.fields.finePrint}
            finePrintOrnamentType={headlineData?.fields.finePrintOrnamentType}
            photo={headlineData?.fields.image}
            references={headlineData?.fields.references}
            showLogo={!isEmbedded || isPromo}
            subcopy={headlineData?.fields.subcopy}
            title={
              step === STEPS.appointmentConfirmation &&
              !submittedAppointment &&
              headlineData?.fields.alternateTitle
                ? `${headlineData?.fields.alternateTitle}${formState?.contactInput?.["First Name"] ? `,\n${formState?.contactInput?.["First Name"]}.` : ""}`
                : submittedAppointment
                  ? `${title}${formState?.contactInput?.["First Name"] ? `,\n${formState?.contactInput?.["First Name"]}.` : ""}`
                  : title
            }
          />
        )}

        <div className={styles.formContent}>
          <div className={styles.formContentInner}>
            {errorMessage && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}

            {step === STEPS.contactInformation && (
              <ContactInformation
                ctaEvent={ctaEvent}
                currentFacility={currentFacility}
                description={contentfulData.contactInformationDescription}
                formState={formState}
                isEmbedded={isEmbedded}
                isLoading={isActive}
                onGuidedWorkoutClick={handleGuidedWorkoutClick}
                onSelectClubClick={handleClubEdit}
                referringId={referringId}
                setFormState={setFormState}
                setIsLoading={(isLoading) => {
                  isLoading
                    ? incrementActivityCount()
                    : decrementActivityCount();
                }}
                setNewDataLayer={setNewDataLayer}
                showGuidedWorkoutCta={showGuidedWorkoutCta}
                showJoinAction={!contentfulData?.hideJoinAction}
                showLeadAction={!contentfulData?.hideLeadAction}
                title={contentfulData.contactInformationTitle}
              />
            )}

            {step === STEPS.scheduleAppointment &&
              (showScheduleVisitByURLs || referringId) && (
                <ScheduleAppointment
                  appointment={submittedAppointment}
                  appointmentType={showGuidedWorkouts ? "Workout" : "Visit"}
                  appointments={
                    showGuidedWorkouts ? workoutAppointments : visitAppointments
                  }
                  clubName={currentFacility?.webName}
                  isEditing={!storedState?.referringId && isInEditMode}
                  isReferral={!!referringId || !!storedState?.referringId}
                  onClubEdit={handleClubEdit}
                  onSchedule={
                    showGuidedWorkouts
                      ? handleWorkoutSchedule
                      : handleVisitSchedule
                  }
                  onSkip={
                    showGuidedWorkouts ? handleWorkoutSkip : handleVisitSkip
                  }
                  showCorpLeadIneligibility={formState.showNonEligibleBanner}
                  showGuidedWorkouts={showGuidedWorkouts}
                />
              )}

            {step === STEPS.appointmentConfirmation && (
              <>
                <AppointmentConfirmation
                  appointment={submittedAppointment}
                  appointmentType={showGuidedWorkouts ? "Workout" : "Visit"}
                  facility={currentFacility}
                  onEditAppointment={handleEditAppointment}
                />

                {personalizeOptionsStep === 5 && (
                  <ClubGallery
                    clubName={currentFacility.webName}
                    slides={gallerySlides}
                  />
                )}

                <PersonalizeQuiz
                  onCtaClick={handleQuizCtaClick}
                  personalizeOptionsStep={formState.personalizeOptionsStep}
                  setNewDataLayer={setNewDataLayer}
                />
              </>
            )}

            {isActive && <Loader className={styles.loader} color="light" />}
          </div>
        </div>

        {!isEmbedded && !isPromo && (
          <ExitFlowConfirmation
            defaultExitPath="/"
            description="Your progress will not be saved."
            skipConfirmation={true}
          />
        )}
      </section>
    </StyledEngineProvider>
  );
};

LeadFlow.propTypes = {
  ctaEvent: PropTypes.string,
  data: PropTypes.object.isRequired,
  facility: PropTypes.object,
  isClubDetail: PropTypes.bool,
  isPromo: PropTypes.bool,
  leadViewDataValue: PropTypes.string,
  page: PropTypes.string,
  referringId: PropTypes.string,
};

LeadFlow.defaultProps = {
  isClubDetail: false,
  isPromo: false,
  leadViewDataValue: "lead-view",
};

export default LeadFlow;
