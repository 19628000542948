import { PropTypes } from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import styles from "./ConfirmationModal.module.scss";

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
 */
const ConfirmationModal = ({
  cancelButtonText = "No",
  children,
  confirmButtonText = "Yes",
  description,
  disabled = false,
  onCancel = () => {},
  onConfirm,
  title = "Are you sure?",
}) => {
  const dialogRef = useRef(null);
  const [hasBackdrop, setHasBackdrop] = useState(false);

  /**
   *
   */
  const handleCancelClick = () => {
    document.body.removeAttribute("inert");

    if (dialogRef.current.close) {
      dialogRef.current.open && dialogRef.current.close();
    } else {
      dialogRef.current.removeAttribute("open");
      setHasBackdrop(false);
    }

    document.activeElement?.blur();

    onCancel();
  };

  /**
   *
   */
  const handleConfirmClick = () => {
    document.body.removeAttribute("inert");
    onConfirm();
  };

  /**
   *
   */
  const handleShowModalClick = (e) => {
    e && e.preventDefault();

    if (disabled) {
      onConfirm();

      return false;
    }

    document.body.setAttribute("inert", "");

    if (dialogRef.current.showModal) {
      !dialogRef.current.open && dialogRef.current.showModal();
    } else {
      dialogRef.current.setAttribute("open", "");
      setHasBackdrop(true);
    }

    document.activeElement?.blur();
  };

  /**
   *
   */
  useEffect(() => {
    const handleCancelOrCloseEvent = () => {
      document.body.removeAttribute("inert");
      document.activeElement?.blur();
      onCancel();
    };

    const currentDialogRef = dialogRef?.current;

    currentDialogRef?.addEventListener("cancel", handleCancelOrCloseEvent);
    currentDialogRef?.addEventListener("close", handleCancelOrCloseEvent);

    return () => {
      currentDialogRef?.removeEventListener("cancel", handleCancelOrCloseEvent);
      currentDialogRef?.removeEventListener("close", handleCancelOrCloseEvent);
    };
  }, [onCancel]);

  /**
   *
   */
  return (
    <>
      <button
        className={styles.showModalButton}
        onClick={handleShowModalClick}
        type="button"
      >
        {children}
      </button>

      <dialog className={styles.dialog} ref={dialogRef}>
        <header>
          <h2 className={styles.title}>{title}</h2>
        </header>

        {description && <p className={styles.description}>{description}</p>}

        <form className={styles.buttons} method="dialog">
          <button
            className={styles.confirmButton}
            name="ConfirmationModal - Confirm Button"
            onClick={handleConfirmClick}
          >
            {confirmButtonText}
          </button>
          <button
            className={styles.cancelButton}
            name="ConfirmationModal - Cancel Button"
            onClick={handleCancelClick}
          >
            {cancelButtonText}
          </button>
        </form>
      </dialog>

      {hasBackdrop && <span className={styles.backdrop} />}
    </>
  );
};

ConfirmationModal.propTypes = {
  cancelButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirmButtonText: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default ConfirmationModal;
