import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import CorpJoinFlowApi from "../api";
import { getStoredState } from "../utils";
import styles from "./EmailCodeConfirmation.module.scss";

const EmailCodeConfirmation = ({
  companyEmail,
  companyName,
  config,
  firstName,
  isLoading,
  onValidCode,
  setIsLoading,
  setNewDataLayer,
}) => {
  const {
    ConfirmationCodeError,
    ConfirmationScreenGreatNews,
    ConfirmationScreenPartnered,
    ConfirmationCodeTitle,
    ConfirmationScreenCode,
  } = config;
  const useCreateRef = () => useRef(null);
  const otpInputs = Array.from({ length: 4 }, useCreateRef);
  const defaultTimeout = 15 * 60;
  const [time, setTime] = useState(defaultTimeout);
  const [resetTimer, setResetTimer] = useState(0);
  const [isCodeChecked, setIsCodeChecked] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const countdown = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(countdown); //time up

          return 0;
        }

        return prevTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [resetTimer]);

  const handleInputChange = async (index, event) => {
    const { value } = event.target;
    const isValidInput = /^[0-9]$/.test(value);

    if (!isValidInput) {
      event.preventDefault();

      return;
    }

    if (index < otpInputs.length - 1 && value !== "") {
      otpInputs[index + 1].current.focus();
    }

    const otpValue = otpInputs.reduce((otpString, inputRef) => {
      return (
        otpString +
        (inputRef && inputRef.current ? inputRef.current.value : "0")
      );
    }, "");

    if (index === otpInputs.length - 1 && value !== "") {
      setIsLoading(true);

      const emailCodeVerifyApiResult = await CorpJoinFlowApi.verifyEmailToken(
        companyEmail,
        otpValue
      );

      setIsCodeChecked(true);

      if (
        emailCodeVerifyApiResult?.status === 200 &&
        emailCodeVerifyApiResult?.data?.verified
      ) {
        setIsCodeValid(true);

        onValidCode();

        setIsLoading(false);
      } else {
        setIsLoading(false);

        setIsCodeValid(false);
      }
    }
  };

  const handleResendCode = async () => {
    const corpInfo = getStoredState();

    if (!corpInfo) {
      //Object no longer exists in memory,
      //we assume the user has left and reload
      //which will show the initial form
      router.reload(window.location.pathname);

      return;
    }

    setTime(defaultTimeout);

    if (time === 0) {
      setResetTimer(resetTimer + 1);
    }

    setIsCodeValid(false);

    setIsCodeChecked(false);

    setNewDataLayer({
      click: {
        destination: "NA",
        location: "Resend Code | body",
        name: "Resend Code",
      },
      event: "mod-click",
      module: {
        name: "ResendTapped",
        position: "1:1",
        type: "text",
      },
    });

    await CorpJoinFlowApi.resendEmailToken(companyEmail);

    otpInputs.forEach((inputRef) => {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    });
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !event.target.value) {
      otpInputs[index - 1].current.focus();
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <span className={styles.emailCodeMainLabel}>
        {ConfirmationScreenGreatNews} {firstName}, {companyName}{" "}
        {ConfirmationScreenPartnered}
      </span>

      <p className={styles.emailCodeSubText}>
        {ConfirmationScreenCode} {companyEmail}
      </p>

      <p className={styles.emailCodeExpiryText} data-expired={time === 0}>
        Code expires in {formatTime(time)}
      </p>

      <p className={styles.confirmationText}>{ConfirmationCodeTitle}</p>

      <p className={styles.confirmationText}>
        {otpInputs.map((inputRef, index) => (
          <input
            className={`${styles.otpInput}`}
            disabled={isLoading}
            inputMode="numeric"
            key={`digit-${index + 1}`}
            maxLength={1}
            onChange={(e) => handleInputChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            placeholder="0"
            ref={inputRef}
            type="text"
          />
        ))}
      </p>

      {isCodeChecked && !isCodeValid && (
        <p className={styles.errorText}>{ConfirmationCodeError}</p>
      )}

      <a className={styles.resendText} onClick={handleResendCode}>
        {time == 0 ? "Resend new Code" : "Resend Code"}
      </a>
    </>
  );
};

EmailCodeConfirmation.propTypes = {
  companyEmail: PropTypes.string,
  companyName: PropTypes.string,
  config: PropTypes.object,
  firstName: PropTypes.string,
  isLoading: PropTypes.bool,
  onValidCode: PropTypes.func,
  setIsLoading: PropTypes.func,
  setNewDataLayer: PropTypes.func,
};

export default EmailCodeConfirmation;
