import PropTypes from "prop-types";
import React from "react";

import Loader from "@/components/Loader";

import styles from "./index.module.scss";

const SpinnerWithOverlay = ({ color, posValue }) => {
  return (
    <div className={styles.loading} style={{ position: posValue }}>
      <Loader color={color} />
    </div>
  );
};

SpinnerWithOverlay.propTypes = {
  color: PropTypes.string,
  posValue: PropTypes.string,
};

SpinnerWithOverlay.defaultProps = {
  color: "dark",
};

export default SpinnerWithOverlay;
