import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";

import { SESSION_STORAGE_KEYS } from "@/constants";

const CACHE_TTL = 3600000;

const NAMED_LOCATIONS = {
  CA: {
    latitude: 34.0674084,
    longitude: -118.4033688,
  },
  CAN: {
    latitude: 43.6532,
    longitude: -79.3832,
  },
  CT: {
    latitude: 41.0700644,
    longitude: -73.4925335,
  },
  DC: {
    latitude: 38.9821841,
    longitude: -77.0963788,
  },
  FL: {
    latitude: 25.9573136,
    longitude: -80.1432508,
  },
  IL: {
    latitude: 41.899641,
    longitude: -87.625122,
  },
  MA: {
    latitude: 42.3530525,
    longitude: -71.0631123,
  },
  MI: {
    latitude: 42.543885,
    longitude: -83.283294,
  },
  NJ: {
    latitude: 40.9729964,
    longitude: -74.0796539,
  },
  NY: {
    latitude: 40.70626068115234,
    longitude: -74.09484100341797,
  },
  TX: {
    latitude: 32.8180318,
    longitude: -96.8029574,
  },
  UK: {
    latitude: 51.5285582,
    longitude: -0.2416805,
  },
  WA: {
    latitude: 47.60898,
    longitude: -122.33476,
  },
};

const useLocation = (
  options = {
    enableHighAccuracy: true,
    timeout: 5000,
  }
) => {
  const isFetching = useRef(false);
  const optionsRef = useRef(options);
  const router = useRouter();
  const [state, setState] = useState({
    error: null,
    isLoading: true,
    latitude: null,
    longitude: null,
    timestamp: null,
  });

  const onSuccess = useCallback(({ coords, timestamp }) => {
    setState({
      isLoading: false,
      latitude: coords.latitude,
      longitude: coords.longitude,
      timestamp,
    });

    sessionStorage?.setItem(
      SESSION_STORAGE_KEYS.USER_GEOLOCATION,
      JSON.stringify({
        latitude: coords.latitude,
        longitude: coords.longitude,
        timestamp,
      })
    );

    console.info("Location queried:", {
      latitude: coords.latitude,
      longitude: coords.longitude,
    });

    isFetching.current = false;
  }, []);

  const onError = useCallback((error) => {
    setState((previousState) => ({
      ...previousState,
      error: error?.message,
      isLoading: false,
    }));

    isFetching.current = false;
  }, []);

  const updateLocation = useCallback(
    (latitude, longitude) => {
      if (!isFetching.current) {
        isFetching.current = true;

        if (latitude && longitude) {
          onSuccess({
            coords: {
              latitude,
              longitude,
            },
            timestamp: new Date().getTime(),
          });
        } else {
          const cachedLocation = JSON.parse(
            sessionStorage?.getItem(SESSION_STORAGE_KEYS.USER_GEOLOCATION)
          );

          if (cachedLocation?.timestamp) {
            if (new Date().getTime() - cachedLocation.timestamp <= CACHE_TTL) {
              setState({
                ...cachedLocation,
                isLoading: false,
              });

              isFetching.current = false;

              return;
            }
          }

          if (navigator?.geolocation) {
            navigator.geolocation.getCurrentPosition(
              onSuccess,
              onError,
              optionsRef.current
            );
          } else {
            setState((previousState) => ({
              ...previousState,
              error: "Geolocation is not supported by browser",
              isLoading: false,
            }));
          }
        }
      }
    },
    [onError, onSuccess]
  );

  useEffect(() => {
    let namedLocation;

    if (router?.query?.location) {
      namedLocation = NAMED_LOCATIONS[router?.query?.location];
    }

    updateLocation(namedLocation?.latitude, namedLocation?.longitude);
  }, [router?.query?.location, updateLocation]);

  return {
    ...state,
    updateLocation,
  };
};

export default useLocation;
