import JoinCorpApiClient from "@/api/client/entities/join-corp";
import LeadAppointmentsApiClient from "@/api/client/entities/lead-appointments";
import { ACDL_JOIN_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/join-flow";
import FormattingUtils from "@/utils/helpers/formatting";

import { deriveEmailPayloadFromState, derivePayloadFromState } from "./utils";

const CorpJoinFlowApi = {
  /**
   *
   */
  createNewCorp: async (formState, setNewDataLayer) => {
    try {
      let result;

      if (formState) {
        result = await JoinCorpApiClient.createAbandonedCorp({
          ...derivePayloadFromState(formState),
          id: null,
        });
      }

      setNewDataLayer(
        ACDL_JOIN_FLOW_TRACKING.joinFlowLeadSubmit(
          FormattingUtils.encode(formState.contactInput["Email Address"]),
          result?.TransactionId,
          formState.outreachCode,
          `${formState.ctaLabel.toLowerCase()} | body`
        )
      );

      return result;
    } catch (error) {
      setNewDataLayer(ACDL_JOIN_FLOW_TRACKING.joinFlowLeadError(error));
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  createEmailCode: async (formState) => {
    try {
      const payload = deriveEmailPayloadFromState(formState);

      let result;

      if (formState) {
        result = await JoinCorpApiClient.createAbandonedCorpEmailVerify({
          ...payload,
          id: null,
        });
      }

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  verifyEmailToken: async (companyEmail, otpValue) => {
    try {
      let result;

      if (otpValue) {
        result = await JoinCorpApiClient.createAbandonedCorpEmailTokenVerify(
          companyEmail,
          otpValue
        );
      }

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  resendEmailToken: async (companyEmail) => {
    try {
      let result;

      if (companyEmail) {
        result =
          await JoinCorpApiClient.resendAbandonedCorpEmailTokenVerify(
            companyEmail
          );
      }

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  getOpenLeadTimesFromFacilityId: async (facilityId) => {
    try {
      const result = await LeadAppointmentsApiClient.findById(facilityId);

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  updateAbandonedLead: async (formState) => {
    try {
      const result = await JoinCorpApiClient.updateAbandonedCorpLead(
        derivePayloadFromState(formState)
      );

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  updateLead: async (leadFlowState) => {
    try {
      const result = await JoinCorpApiClient.updateCorpLead(leadFlowState);

      return result;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },

  /**
   *
   */
  verifyValidCorpEmail: async (corpEmail, facilityId) => {
    try {
      const result = await JoinCorpApiClient.verifyDomain(
        corpEmail,
        facilityId
      );

      return result?.data;
    } catch (error) {
      console.error("error: ", error);

      return;
    }
  },
};

export default CorpJoinFlowApi;
