{
  /*
  Takes content which contains dynamic placeholders and object which contains mapping
  for every placeholder.
  {
    placeholder: replace with value here.
  }
*/
}

const dynamicContentReplacer = (content, replacerConfig) => {
  if (typeof content === "string") {
    return content.replace(/{{([^}]*)}}/gi, (matched) => {
      return replacerConfig[matched] || matched;
    });
  }

  console.error("dynamicContentReplacer() expects content to be a string");

  return "";
};

export default dynamicContentReplacer;
