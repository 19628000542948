import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./index.module.scss";

const ContactConsentNotice = ({ className }) => {
  return (
    <small className={classNames(styles.contactConsentNotice, className)}>
      By continuing, I agree to the Equinox{" "}
      <a href="/terms" target="_blank">
        Terms & Conditions
      </a>
      ,{" "}
      <a href="/privacy" target="_blank">
        Privacy Policy
      </a>{" "}
      and that Equinox brand companies and their membership advisors can contact
      me regarding promotions, marketing, products, services, and other
      information that may interest me.
    </small>
  );
};

ContactConsentNotice.propTypes = {
  className: PropTypes.string,
};

export default ContactConsentNotice;
