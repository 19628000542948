import { v4 as uuidv4 } from "uuid";

import {
  NEWSLETTER_DECLINE_ANSWER_ID,
  NEWSLETTER_SUBSCRIBE_ANSWER_ID,
} from "@/constants";
import { utils as userFlowUtils } from "@/features/user-flow";

import { getActualURL, getCookieValue } from "../../../utils/helpers/document";
import logger from "../../../utils/logger";
import ApiClient from "../client";

export const DEFAULT_JOIN_OUTREACHCODE = "npeqxNewJoinFlowLead";
export const ECID_COOKIE_NAME = "ECID";
export const JOIN_OUTREACHCODE_SUFFIX = "_JOIN";

const JoinLead = {
  namespace: "joinLead",

  basePath: "/v6/acq/lead/residential",

  async createAbandonedLead({
    id,
    firstName,
    lastName,
    facilityId,
    email,
    newsletter,
    phone,
    joinOutreachCode,
    outreachCode,
    ecid = getCookieValue(ECID_COOKIE_NAME),
    receiveTextAlerts = false,
    sourceUrl = getActualURL(),
    companyEmail,
    shareId,
    referringId,
    memberId,
    isCorpLead,
  }) {
    const endpoint = `${this.basePath}`;
    const TransactionId = uuidv4();

    return ApiClient._post(endpoint, {
      data: {
        Answers: [
          {
            additionalInfo: "",
            answerId: newsletter
              ? NEWSLETTER_SUBSCRIBE_ANSWER_ID
              : NEWSLETTER_DECLINE_ANSWER_ID,
          },
        ],
        Ecid: ecid,
        EmailAddress: email,
        FacilityId: facilityId,
        FirstName: firstName,
        Id: id,
        LastName: lastName,
        OutreachCode: isCorpLead
          ? outreachCode
          : userFlowUtils.modifyOutreachCodeForVwoCampaign(
              joinOutreachCode ||
                outreachCode + JOIN_OUTREACHCODE_SUFFIX ||
                DEFAULT_JOIN_OUTREACHCODE
            ),
        PhoneNumber: phone,
        SourceUrl: sourceUrl,
        TransactionId,
        ...(companyEmail && { companyEmail }),
        ...(memberId && { memberId }),
        receiveTextAlerts,
        ...(referringId && { referringId }),
        ...(shareId && { shareId }),
      },
    }).then((resp) => {
      if (!resp.data) {
        logger.warn(`API|Join Lead: Could not generate lead`);

        return null;
      }

      return { ...resp, TransactionId };
    });
  },
};

export default JoinLead;
