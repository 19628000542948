import axios from "axios";

import Utilities from "@/api/user";

export const fetchApi = (
  apiUrl,
  apiMethod,
  skipAuthorization,
  apiData = null
) => {
  const headers = {
    "Access-Control-Allow-Origin": window.location.origin,
    "Content-Type": "application/json",
  };

  if (!skipAuthorization) {
    headers.Authorization = Utilities.getAuthToken();
  }

  return axios({
    data: apiData,
    headers,
    method: apiMethod,
    url: `${process.env.NEXT_PUBLIC_EQUINOX_API_HOST}${apiUrl}`,
  });
};
