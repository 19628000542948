import { useCallback, useState } from "react";

const useActivityCount = () => {
  const [count, setCount] = useState(0);

  const decrementActivityCount = useCallback(() => {
    setCount((count) => {
      if (count > 0) {
        return count - 1;
      }

      return 0;
    });
  }, []);

  const incrementActivityCount = useCallback(() => {
    setCount((count) => {
      if (count < 0) {
        return 1;
      }

      return count + 1;
    });
  }, []);

  const resetActivityCount = useCallback(() => {
    setCount(0);
  }, []);

  return {
    decrementActivityCount,
    incrementActivityCount,
    isActive: count > 0,
    resetActivityCount,
  };
};

export default useActivityCount;
