import PropTypes from "prop-types";
import React from "react";

import PencilIcon from "@/assets/icons/pencil-icon.svg";
import ConfirmationModal from "@/components/ConfirmationModal";

import styles from "./AppointmentClubLabel.module.scss";

const AppointmentClubLabel = ({
  canEdit = false,
  clubName,
  onClick,
  showGuidedWorkouts = false,
}) => {
  return (
    <dl className={styles.appointmentClubLabel}>
      <dt>Selected club:</dt>

      <dd className={styles.clubName}>
        {clubName}
        {canEdit && (
          <ConfirmationModal
            description="Guided workouts are only available at this location."
            disabled={!showGuidedWorkouts}
            onConfirm={onClick}
          >
            <PencilIcon className={styles.pencilIcon} />
          </ConfirmationModal>
        )}
      </dd>
    </dl>
  );
};

AppointmentClubLabel.propTypes = {
  canEdit: PropTypes.bool,
  clubName: PropTypes.string,
  onClick: PropTypes.func,
  showGuidedWorkouts: PropTypes.bool,
};

export default AppointmentClubLabel;
