import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import LinkArrow from "@/assets/icons/link-arrow-2.svg";

import styles from "./index.module.scss";

const LinkWithArrow = ({
  link,
  linkCopy,
  stylesFromParent,
  className,
  color,
  onClick,
}) => {
  let linkClass;

  if (!stylesFromParent) {
    linkClass = color === "white" ? styles.linkWhite : styles.linkBlack;
  }

  if (onClick) {
    return (
      <button
        className={classNames(
          styles.linkWithArrow,
          className,
          stylesFromParent && stylesFromParent,
          !stylesFromParent && linkClass
        )}
        name="Links:LinkWithArrow"
        onClick={onClick}
      >
        <span>{linkCopy}</span>
        <LinkArrow aria-hidden={true} />
      </button>
    );
  }

  return (
    <a
      className={`${className} ${
        stylesFromParent ? stylesFromParent : linkClass
      }`}
      href={link}
    >
      {linkCopy}
      <LinkArrow aria-hidden={true} />
    </a>
  );
};

LinkWithArrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.string,
  linkCopy: PropTypes.string,
  onClick: PropTypes.func,
  stylesFromParent: PropTypes.string,
};

LinkWithArrow.defaultProps = {
  color: "white",
};

export default LinkWithArrow;
